.shimmer-purple {
  position: relative;
  overflow: hidden;
}

.shimmer-purple:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(233, 233, 233, 0.5) 0,
    rgba(233, 233, 233, 0.4) 50%,
    rgba(233, 233, 233, 0.3) 100%
  );
  animation: shimmer 2s ease-out infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(0%);
    opacity: 0;
  }
}
